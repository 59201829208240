import React, { useEffect } from "react"
import qs from "qs"
import kwesforms from "kwesforms"

import { useForm } from "react-hook-form"

import axios from "axios"
import { navigate } from "gatsby-link"
import Layout from "../../../components/layout"

import Section from "../../../components/Section"
import Image from "../../../components/actions/Image"
import Seo from "../../../components/seo"
import Input from "../../../components/actions/Input"
import Select from "../../../components/actions/Select"
export default function Form(props) {
  const value = qs.parse(
    typeof window !== "undefined"
      ? window.location.search.substring(1)
      : `name=test`
  )
  return (
    <Layout>
      <Seo title={"Booking Villa " + value.villaName} />
      <Section>
        <Image name={value.villaName} src={value.image} />
        <Forms value={value} />
      </Section>
    </Layout>
  )
}

const Forms = ({ value }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      villaName: value.villaName,
      from: value.start,
      to: value.end,
      adults: value.adults,
      children: value.children,
    },
  })

  const onSubmit = async data => {
    const url =
      "https://sxvls3nwxi.execute-api.eu-west-1.amazonaws.com/master/actions/villa/bookings"

    const response = await axios.post(url, data, {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    console.log(data)

    navigate("/actions/villa/complete")
  }
  return (
    <div>
      <form
        className="kwes-form"
        // action="https://kwes.io/api/foreign/forms/ioOY3NcSQZV5qGeQ626w"
        // redirect="/actions/villa"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="shadow-xl p-4 lg:p-8 grid grid-cols-1 lg:grid-cols-2 gap-4 xl:gap-8">
          <h4 className="col-span-1 lg:col-span-2 text-[24px] font-serif">
            Your Details
          </h4>
          <Input
            rule="required|max:255"
            label="Villa Name"
            name="villaName"
            type="text"
            field="villaName"
            register={register}
            readOnly
          />
          <Input
            rule="required"
            label="Your Email"
            name="email"
            type="email"
            field="email"
            register={register}
          />
          <Input
            rule="required"
            label="Your Phone"
            name="phone"
            type="phone"
            field="phone"
            register={register}
            className="col-start-1 row-start-4 lg:row-start-3"
          />
          <Input
            rule="required"
            label="Your Country"
            name="country"
            type="country"
            field="country"
            register={register}
            className="row-start-5 lg:row-start-3"
          />
        </div>
        <div className="shadow-xl p-4  lg:p-8 grid grid-cols-1 lg:grid-cols-2 gap-4 xl:gap-8 mt-8">
          <h2 className="col-span-1 lg:col-span-2 text-[24px] font-serif">
            Your Requirements
          </h2>
          <Input
            rule="required"
            label="Check In"
            name="Check-in"
            field="from"
            register={register}
            type="date"
            readOnly
          />
          <Input
            rule="required"
            label="Check Out"
            name="Check-out"
            field="to"
            register={register}
            type="date"
            readOnly
          />
          {/* <Input
            rule="required"
            label="Bedrooms"
            field="bedrooms"
            register={register}
            name="Bedroom"
            type="number"
          /> */}
          <Input
            rule="required"
            label="Adults"
            name="Adults"
            field="adults"
            register={register}
            type="number"
            readOnly
          />

          {/* <Select
            rule="required"
            label="Budget"
            field="budget"
            register={register}
            name="budgets"
            options={[
              "5000 - 10,000",
              "10,000 - 15,000",
              "15,000 - 20,000",
              "20,000 - 25,000",
              "25,000 - 35,000",
              "35,000 - 50,000",
              "50,000+",
            ]}
          /> */}
          <Input
            rule="required"
            label="Children"
            name="Children"
            type="number"
            field="children"
            register={register}
            readOnly
          />
          <Input
            textarea
            rule="required"
            field="notes"
            register={register}
            label="Describe your perfect holiday villa"
            name="notes"
            className="col-span-1 lg:col-span-2"
          />
        </div>

        <div className="mt-8 mr-4 flex justify-end items-center">
          <button
            type="submit"
            className="px-12 py-4 outline-none focus:outline-none text-sm font-bold text-white bg-black"
          >
            Enquire Now
          </button>
        </div>
      </form>
    </div>
  )
}
